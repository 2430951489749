<template>
  <div class="app-container">
    <CrudTable
      entity="AppSetting"
      :allow-actions="false"
      :columns="columns"
      :show-action-icons-in-list="false"
      :on-row-dbl-click="() => {}"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'key', label: 'common.key', sortable: false },
        { field: 'value', label: 'common.value', filter: { type: 'none' }, sortable: false }
      ]
    };
  }
};
</script>
